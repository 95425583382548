import React from "react";

export const GuestpassVideo = ({ src }) => {
  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      id="bgvid"
      ref={(video) => {
        if (!video) return;
        video.muted = true;
        video.play();
      }}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};
