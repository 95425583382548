import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const MeetFitplanComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t("meetFitPlan")}</Title>
      <br></br>
      <GreenDivider />
      <br />
      <br />
      <BulletPointBox>
        <IconImage
          src="https://cdn.sanity.io/images/tpska9ut/production/ccf0a2ada1d2f222ceb960441d1d3ac2ed77e9c2-233x119.svg"
          alt="Fitplan icon Heart"
        />
        <Subtitle>{t("match")}</Subtitle>
        <ParagraphContent>{t("getMatchedWithProfessionals")}</ParagraphContent>
      </BulletPointBox>
      <GreyDivider />

      <BulletPointBox>
        <IconImage
          src="https://cdn.sanity.io/images/tpska9ut/production/cabdcfd124fd18d8470a350b6df8ece4d51ddc3c-233x118.svg"
          alt="Fitplan icon Phone"
        />
        <Subtitle>{t("training")}</Subtitle>
        <ParagraphContent>{t("keepTrackOfYourStats")}</ParagraphContent>
      </BulletPointBox>
      <GreyDivider />

      <BulletPointBox>
        <IconImage
          src="https://cdn.sanity.io/images/tpska9ut/production/dcd462c49c1d5632ec3c50d076845cef5a409760-233x119.svg"
          alt="Fitplan icon 24/7"
        />
        <Subtitle>{t("availability")}</Subtitle>
        <ParagraphContent>{t("getAccessToTrainers")}</ParagraphContent>
      </BulletPointBox>
      <GreyDivider />

      <BulletPointBox>
        <IconImageVariety
          src="https://cdn.sanity.io/images/tpska9ut/production/71221cdc9831b6a83659e2926b6ea0824a571178-119x64.svg"
          alt="Fitplan icon Infinity"
        />
        <Subtitle>{t("variety")}</Subtitle>
        <ParagraphContent>{t("accessToPlans")}</ParagraphContent>
      </BulletPointBox>
      <GreyDivider />
    </>
  );
};

const BulletPointBox = styled.div`
  width: 200px;
  margin: 10px calc(50vw - 100px);

  display: block;
  @media (min-width: 900px) {
    margin: auto 10px;

    display: inline-block;
  }
  @media (min-width: 1200px) {
  }
`;
const Title = styled.h1`
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;

  margin: 0 auto;
  padding: 0;
`;
const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`;
const IconImage = styled.img`
  height: 100px;
`;
const IconImageVariety = styled(IconImage)`
  width: 100px;
`;
const ParagraphContent = styled.p`
  width: 200px;
  margin: 10px auto;
`;
//===============================================>       Dividers
const GreenDivider = styled.div`
  background-image: linear-gradient(79deg, #00bd98, #28d95a);
  width: 250px;
  height: 3px;
  margin: 0 calc(50vw - 125px);
`;
const GreyDivider = styled.div`
  background-image: linear-gradient(#1d222b, #777777, #1d222b);

  width: 140px;
  height: 2px;
  margin: 20px calc(50vw - 70px);

  display: block;

  @media (min-width: 900px) {
    display: none;
  }
  @media (min-width: 1200px) {
  }
`;
