import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { Subscribe } from "../../components/subscribe/GuestPassSubscribe";
import { GuestpassVideo } from "../../components/guestpass/GuestpassVideo";

import { MeetFitplanComponent } from "../../components/guestpass/MeetFitplanComponent";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";

const tabletBreakpoint = "900px";
const mobileBreakpoint = "600px";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const ThirtyDayTrialPage = ({ data: { header, ribbon, footer } }) => {
  const { t, language } = useTranslation();
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <LocalWrapper>
        <VideoWrapper>
          <HeroWideBox>
            <GuestpassVideo src="https://player.vimeo.com/external/391321633.hd.mp4?s=7f95236c8df2d8d0bdadd944d2f4c601c4853a11&profile_id=174" />
          </HeroWideBox>
          <HeroMobileBox>
            <GuestpassVideo src="https://player.vimeo.com/external/391321606.hd.mp4?s=6d34205af3cabc30ca12dd7efa4237f0bd3a3e05&profile_id=174" />
          </HeroMobileBox>
        </VideoWrapper>

        <SideBySideContainer>
          <RightSide>
            <Header>{t("claimYourGuestPass")}</Header>
            <GiftCard />
          </RightSide>
          <LeftSide>
            <Header>{t("yourGoalsAreWaiting")}</Header>
            <Subscribe
              backgroundColor={"transparent"}
              newCustomersOnly={
                <div>
                  <h1>ERROR</h1>
                  <h2>This deal is for new members only.</h2>
                </div>
              }
            />
          </LeftSide>
          <RulesBox>{t("forNewUsersOnly")}</RulesBox>
        </SideBySideContainer>

        <BulletPointContainer>
          <MeetFitplanComponent />
        </BulletPointContainer>
      </LocalWrapper>
    </Layout>
  );
};

const BulletPointContainer = styled.div`
  text-align: center;
  background-color: #1d222b;
  padding: 40px 0;
  position: relative;
`;

const VideoWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

const LocalWrapper = styled.div`
  font-family: Barlow;
  color: #fff;
  background-color: #1d222b;
  position: relative;
`;

const Break = styled.br`
  @media (min-width: ${tabletBreakpoint}) {
    display: none;
  }
`;

const HeroMobileBox = styled.div`
  text-align: center;
  overflow: hidden;
  visibility: visible;
  height: 100vh;

  video {
    height: 100vh;
    width: 100vw;
    z-index: 1 !important;
  }

  @media (min-width: ${mobileBreakpoint}) {
    display: none;
    visibility: hidden;
  }
`;

const HeroWideBox = styled.div`
  display: none;
  visibility: hidden;

  video {
    width: 100vw;
    height: 41vw;
    z-index: 1 !important;
  }

  @media (min-width: ${mobileBreakpoint}) {
    display: block;
    visibility: visible;
  }
`;

const SideBySideContainer = styled.div`
  color: #fff;
  background-image: linear-gradient(transparent, #000);
  background-color: rgba(0, 0, 0, 0.1);

  width: 100vw;
  height: 100vh;

  overflow: scroll;

  position: relative;
  text-align: center;

  z-index: 10;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100vw;
  grid-template-areas:
    "right"
    "left"
    "bottom";

  @media (min-width: ${mobileBreakpoint}) {
    height: 41vw;

    grid-template-rows: auto;
    grid-template-columns: 50vw 50vw;
    grid-template-areas:
      "left right"
      "bottom bottom";
  }
`;

const Sides = styled.div`
  text-align: center;
  height: 350px;
  vertical-align: top;
  margin: 0 auto;
  z-index: 10;
  @media (min-width: ${mobileBreakpoint}) {
    width: 400px;
    display: inline-block;
    height: 250px;
    margin-top: calc(31vw - 250px);
  }
`;

const LeftSide = styled(Sides)`
  grid-area: left;
`;

const RightSide = styled(Sides)`
  grid-area: right;
`;

const Header = styled.div`
  font-weight: 800;
  text-align: center;
  font-size: 36px;
  color: #fff;
  margin: 5vw auto 20px;
  vertical-align: top;
  display: inline-block;
  z-index: 10;

  @media (min-width: ${tabletBreakpoint}) {
    margin: 0 auto 20px;
    font-size: 30px;
  }
`;

const GiftCard = styled.div`
  background-image: url("https://fitplan-marketing.s3-us-west-2.amazonaws.com/fitplan_guestpass/referal_card.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 175px;
  width: 320px;
  margin: 10px auto;
  border-radius: 17px;
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const RulesBox = styled.div`
  grid-area: bottom;
  display: inline-block;
  vertical-align: bottom;
  margin: 40px auto 0;
  z-index: 10;
  font-size: 14px;
`;

export default ThirtyDayTrialPage;
